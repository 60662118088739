<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h3>
          측정시간 :
          {{
            find출하실 == null
              ? ''
              : yyyyMMddHHmmss(new Date(find출하실.온도.create_time))
          }}
        </h3>
        <div class="btn_wrap">
          <!-- <button
            class="btn_sub1 refresh_btn"
            @click="
              FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
              FETCH_NO_SPINNER('FETCH_PLC_SENSOR', 'PLC 센서');
            "
          >
            재조회
          </button> -->
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? '닫기' : '전체화면' }}
          </button>
        </div>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_body">
          <div class="monitor_card" :class="{ warning_card: !냉장고state }">
            <h2>1층 냉장고</h2>
            <div class="drying_room">
              <div></div>
              <span :class="{ normal_point: 냉장고state }">{{
                냉장고state ? '정상' : '경고'
              }}</span>
            </div>
            <p v-if="find냉장고 == null"></p>
            <p v-else>
              {{ find냉장고.온도.value }}℃ / {{ find냉장고.습도.value }}%
            </p>
          </div>
          <div
            class="monitor_card"
            :class="{ warning_card: !보관창고2층state }"
          >
            <h2>2층 보관창고</h2>
            <div class="drying_room">
              <div></div>
              <span
                :class="{
                  normal_point: 보관창고2층state,
                }"
                >{{ 보관창고2층state ? '정상' : '경고' }}</span
              >
            </div>
            <p v-if="find보관창고2층 == null"></p>
            <p v-else>
              {{ find보관창고2층.온도.value }}℃ /
              {{ find보관창고2층.습도.value }}%
            </p>
          </div>
          <div class="monitor_card" :class="{ warning_card: !출하실state }">
            <h2>출하실</h2>
            <div class="drying_room">
              <div></div>
              <span
                :class="{
                  normal_point: 출하실state,
                }"
                >{{ 출하실state ? '정상' : '경고' }}</span
              >
            </div>
            <p v-if="find출하실 == null"></p>
            <p v-else>
              {{ find출하실.온도.value }}℃ / {{ find출하실.습도.value }}%
            </p>
          </div>
          <div class="monitor_card" :class="{ warning_card: !포장실state }">
            <h2>포장실</h2>
            <div class="drying_room">
              <div></div>
              <span
                :class="{
                  normal_point: 포장실state,
                }"
                >{{ 포장실state ? '정상' : '경고' }}</span
              >
            </div>
            <p v-if="find포장실 == null"></p>
            <p v-else>
              {{ find포장실.온도.value }}℃ / {{ find포장실.습도.value }}%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      plc_sensor_logs: 'getPlcSensorLog',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    출하실state() {
      if (this.find출하실 == null || this.plc_sensors.length < 1) {
        return true;
      } else {
        const found온도 = this.lodash
          .clonedeep(this.plc_sensors)
          .find(x => x.id == this.find출하실.온도.plc_sensor_id);
        const found습도 = this.lodash
          .clonedeep(this.plc_sensors)
          .find(x => x.id == this.find출하실.습도.plc_sensor_id);
        if (found온도 == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(found온도.min_value) >
              this.$makeNumber(this.find출하실.온도.value) ||
            this.$makeNumber(found온도.max_value) <
              this.$makeNumber(this.find출하실.온도.value)
          ) {
            return false;
          } else if (
            this.$makeNumber(found습도.min_value) >
              this.$makeNumber(this.find출하실.습도.value) ||
            this.$makeNumber(found습도.max_value) <
              this.$makeNumber(this.find출하실.습도.value)
          ) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    포장실state() {
      if (this.find포장실 == null || this.plc_sensors.length < 1) {
        return true;
      } else {
        const found온도 = this.lodash
          .clonedeep(this.plc_sensors)
          .find(x => x.id == this.find포장실.온도.plc_sensor_id);
        const found습도 = this.lodash
          .clonedeep(this.plc_sensors)
          .find(x => x.id == this.find포장실.습도.plc_sensor_id);
        if (found온도 == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(found온도.min_value) >
              this.$makeNumber(this.find포장실.온도.value) ||
            this.$makeNumber(found온도.max_value) <
              this.$makeNumber(this.find포장실.온도.value)
          ) {
            return false;
          } else if (
            this.$makeNumber(found습도.min_value) >
              this.$makeNumber(this.find포장실.습도.value) ||
            this.$makeNumber(found습도.max_value) <
              this.$makeNumber(this.find포장실.습도.value)
          ) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    냉장고state() {
      if (this.find냉장고 == null || this.plc_sensors.length < 1) {
        return true;
      } else {
        const found온도 = this.lodash
          .clonedeep(this.plc_sensors)
          .find(x => x.id == this.find냉장고.온도.plc_sensor_id);
        const found습도 = this.lodash
          .clonedeep(this.plc_sensors)
          .find(x => x.id == this.find냉장고.습도.plc_sensor_id);
        if (found온도 == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(found온도.min_value) >
              this.$makeNumber(this.find냉장고.온도.value) ||
            this.$makeNumber(found온도.max_value) <
              this.$makeNumber(this.find냉장고.온도.value)
          ) {
            return false;
          } else if (
            this.$makeNumber(found습도.min_value) >
              this.$makeNumber(this.find냉장고.습도.value) ||
            this.$makeNumber(found습도.max_value) <
              this.$makeNumber(this.find냉장고.습도.value)
          ) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    보관창고2층state() {
      if (this.find보관창고2층 == null || this.plc_sensors.length < 1) {
        return true;
      } else {
        const found온도 = this.lodash
          .clonedeep(this.plc_sensors)
          .find(x => x.id == this.find보관창고2층.온도.plc_sensor_id);
        const found습도 = this.lodash
          .clonedeep(this.plc_sensors)
          .find(x => x.id == this.find보관창고2층.습도.plc_sensor_id);
        if (found온도 == undefined) {
          return true;
        } else {
          if (
            this.$makeNumber(found온도.min_value) >
              this.$makeNumber(this.find보관창고2층.온도.value) ||
            this.$makeNumber(found온도.max_value) <
              this.$makeNumber(this.find보관창고2층.온도.value)
          ) {
            return false;
          } else if (
            this.$makeNumber(found습도.min_value) >
              this.$makeNumber(this.find보관창고2층.습도.value) ||
            this.$makeNumber(found습도.max_value) <
              this.$makeNumber(this.find보관창고2층.습도.value)
          ) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    find출하실() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 출하실온도 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 1);
        if (출하실온도 == undefined) {
          return null;
        }
        const 출하실습도 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 2);
        if (출하실습도 == undefined) {
          return null;
        }
        return {
          온도: 출하실온도,
          습도: 출하실습도,
        };
      }
    },
    find포장실() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 포장실온도 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 3);
        if (포장실온도 == undefined) {
          return null;
        }
        const 포장실습도 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 4);
        if (포장실습도 == undefined) {
          return null;
        }
        return {
          온도: 포장실온도,
          습도: 포장실습도,
        };
      }
    },
    find냉장고() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 냉장고온도 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 9);
        if (냉장고온도 == undefined) {
          return null;
        }
        const 냉장고습도 = this.plc_sensor_logs.find(
          x => x.plc_sensor_id == 10,
        );
        if (냉장고습도 == undefined) {
          return null;
        }
        return {
          온도: 냉장고온도,
          습도: 냉장고습도,
        };
      }
    },
    find보관창고2층() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 보관창고2층온도 = this.plc_sensor_logs.find(
          x => x.plc_sensor_id == 7,
        );
        if (보관창고2층온도 == undefined) {
          return null;
        }
        const 보관창고2층습도 = this.plc_sensor_logs.find(
          x => x.plc_sensor_id == 8,
        );
        if (보관창고2층습도 == undefined) {
          return null;
        }
        return {
          온도: 보관창고2층온도,
          습도: 보관창고2층습도,
        };
      }
    },
    find금속검출기() {
      if (this.plc_sensor_logs.length < 1) {
        return null;
      } else {
        const 통과수량 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 5);
        if (통과수량 == undefined) {
          return null;
        }
        const 불량수량 = this.plc_sensor_logs.find(x => x.plc_sensor_id == 6);
        if (불량수량 == undefined) {
          return null;
        }
        return {
          통과: 통과수량,
          불량: 불량수량,
        };
      }
    },
  },
  methods: {
    playWarningSound() {
      const audio = new Audio(require('@/assets/sound/warning_sound.mp3'));
      audio.play();
    },
    yyyyMMddHHmmss(date) {
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '-' + (MM > 9 ? MM : '0' + MM),
        '-' + (dd > 9 ? dd : '0' + dd),
        ' (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    // clearInterval(this.currentTimeInterval);
  },
  watch: {
    check당침기state(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
    check증삼기state(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
    check출하실온도state(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
    check출하실습도state(newValue) {
      if (newValue == false) {
        this.playWarningSound();
      }
    },
  },
  async created() {
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }

    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;
      console.log('test');
      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR', 'PLC 센서');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    // this.currentTimeInterval = setInterval(() => {
    //   this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    // }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
